
<script>
import DatePicker from 'vue2-datepicker';
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
		DatePicker
	},
	data() {
		return {
			name: 'evaluation_arce',
			form: {
				fortalezas: '',
				debilidades: '',
				oportunidades: '',
				amenazas: '',
				comentariosEvaluacion: '',
				clienteVisitado12Meses: '',
				asistentes: '',
				comentariosSobreVisita: '',
				fechaVisita: '',
				informacionCompetidores: '',
				comentariosInformacionCompetidores: '',
			},
		}
	},
	watch: {
		'client': {
			handler(nVal) {
				this.fillForm()
			},
			deep: true,
			immediate: true
		},
	},
	methods: {
		getClients() {
			return this.client.children
		},
		calcFill() {
			let fields = Object.keys(this.form)

			if(this.form.clienteVisitado12Meses == 'NO') {
				this.form.asistentes = ''
				let index = fields.indexOf('asistentes')
				fields.splice(index, 1)
				this.form.comentariosSobreVisita = ''
				index = fields.indexOf('comentariosSobreVisita')
				fields.splice(index, 1)
				this.form.fechaVisita = ''
				index = fields.indexOf('fechaVisita')
				fields.splice(index, 1)
			}

			if(this.form.informacionCompetidores == 'NO') {
				this.form.comentariosInformacionCompetidores = ''
				let index = fields.indexOf('comentariosInformacionCompetidores')
				fields.splice(index, 1)
			}

			let points = 100 / fields.length
			let fill = 0
			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		
		fillForm() {
			let data = {}
			if(this.client.arce.hasOwnProperty(this.name)) {
				data = {...this.client.arce[this.name]}
			}

			if(!Object.keys(data).length) {
				this.$set(this, 'form', {
					fortalezas: '',
					debilidades: '',
					oportunidades: '',
					amenazas: '',
					comentariosEvaluacion: '',
					clienteVisitado12Meses: '',
					asistentes: '',
					comentariosSobreVisita: '',
					fechaVisita: '',
					informacionCompetidores: '',
					comentariosInformacionCompetidores: '',
				})
				return 
			}

			this.$set(this, 'form', {...data})
		},
		save() {
			let vm = this
			let group = {...this.client}
			group.arceFill[this.name] = this.calcFill()
			group.arce[this.name] =  Object.assign({...this.form})
			vm._updateArce(group)
		},

	
		sumTotal(item, key) {
			if(!item.length) {
				return 0
			}
			let sum = item.reduce((a, c) => {
				let obj = {}
				obj[key] = Number(a[key]) + Number(c[key])
				return obj
			})

			return sum[key]
		},
	
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild'
		}),
		...mapActions({
			_updateArce: 'arceDetails/updateArce'
		})
	}
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="showModal('#formEvaluationModal')">
				Editar
			</button>
		</div>
		<div>
			<div class="">
				<div class="s-collapse__header" data-toggle="collapse" data-target="#c_feg_1" aria-expanded="true" aria-controls="c_feg_1">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> FODA</span></h6>
					</div>
				</div>
				<div id="c_feg_1" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap mx-n2">
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Fortalezas</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.fortalezas">
								</textarea>
							</label>
						</div>
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Debilidades</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.debilidades">
								</textarea>
							</label>
						</div>
						<div class="col-4 p-2"></div>
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Oportunidades</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.oportunidades">
								</textarea>
							</label>
						</div>
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Amenazas</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.amenazas">
								</textarea>
							</label>
						</div>
						<div class="col-4 p-2"></div>
					</div>
				</div>
				<div class="s-collapse__header" data-toggle="collapse" data-target="#c_feg_2" aria-expanded="true" aria-controls="c_feg_2">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Valoración General</span></h6>
					</div>
				</div>
				<div id="c_feg_2" class="collapse show" aria-labelledby="headingOne">
					<div class="py-2">
						<table class="w-100 s-table">
							<thead>
								<tr>
									<th>
										NOMBRE DE FILIAL
									</th>
									<th>
										ACTIVIDAD
									</th>
									<th>
										RATING LARGO
									</th>
									<th>
										CUOTA DE BANQUE...
									</th>
									<th>
										ÚLTIMO AUTORIZADO
									</th>
									<th>
										FORMALIZADO
									</th>
									<th>
										DISPUESTO
									</th>
									<th>
										PROPUESTO
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(c, index) in getClients()">
									<td>
										{{ c.name }}
									</td>
									<td>
										{{c.activity}}
									</td>
									<td>
										CCC+
									</td>
									<td>
										
									</td>
									<td>
										6,000,000
									</td>
									<td>
										0
									</td>
									<td>
										0
									</td>
									<td>
										4,000,000
									</td>
								</tr>
								<tr >
									<td>
										TOTAL
									</td>
									<td>
										
									</td>
									<td>
										CCC+
									</td>
									<td>
										
									</td>
									<td>
										6,000,000
									</td>
									<td>
										0
									</td>
									<td>
										0
									</td>
									<td>
										4,000,000
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="d-flex flex-wrap mx-n2">
						<div class="col-4">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios evaluación</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosEvaluacion">
								</textarea>
							</label>
						</div>
					</div>
				</div>
				<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_feg_3" aria-expanded="true" aria-controls="c_feg_3">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Análisis con respecto Competidores</span></h6>
					</div>
				</div>
				<div id="c_feg_3" class="collapse" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap mx-n2">
						<div class="col-4 p-2">
							<label class="d-block">
								<p class="m-0"><span class="text-danger">*</span> Información de los competidores</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="form.informacionCompetidores">
							</label>
						</div>
						<div class="col-4  p-2" v-if="form.informacionCompetidores == 'SI'">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios sobre Información de los Competidores</p>
								<textarea class="form-control  form-control--disabled" disabled type="text" v-model="form.comentariosInformacionCompetidores">
								</textarea>
							</label>
						</div>
					</div>
				</div>
				<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_feg_4" aria-expanded="true" aria-controls="c_feg_4">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Reuniones con los clientes</span></h6>
					</div>
				</div>
				<div id="c_feg_4" class="collapse" aria-labelledby="headingOne">
					<div class="d-flex py-2 flex-wrap">
						<div class="col-4 p-2">
							<label class="d-block">
								<p class="m-0"><span class="text-danger">*</span> El cliente ha sido visitado en los últimos 12 meses</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="form.clienteVisitado12Meses">
							</label>
						</div>
					
						<div class="col-4 p-2" v-if="form.clienteVisitado12Meses == 'SI'">
							<span class="d-block">
								<p class="m-0"><span class="text-danger">*</span> Fecha</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="form.fechaVisita">
							</span>
						</div>
						<div class="col-4 p-2"  v-if="form.clienteVisitado12Meses == 'SI'">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Asistentes</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="form.asistentes">
							</label>
						</div>
						<div class="col-12  p-2" v-if="form.clienteVisitado12Meses == 'SI'">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios sobre la visita</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosSobreVisita">
								</textarea>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formEvaluationModal" tabindex="-1" role="dialog" aria-labelledby="formEvaluationModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="">
						<div class="s-collapse__header" data-toggle="collapse" data-target="#c_feg_m_1" aria-expanded="true" aria-controls="c_feg_m_1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> FODA</span></h6>
							</div>
						</div>
						<div id="c_feg_m_1" class="collapse show" aria-labelledby="headingOne">
							<div class="d-flex flex-wrap mx-n2">
								<div class="col-4 p-2">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Fortalezas</p>
										<textarea class="form-control" type="text" v-model="form.fortalezas">
										</textarea>
										<characters-available :max="500" :text="form.fortalezas"></characters-available>

									</label>
								</div>
								<div class="col-4 p-2">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Debilidades</p>
										<textarea class="form-control" type="text" v-model="form.debilidades">
										</textarea>
										<characters-available :max="500" :text="form.debilidades"></characters-available>

									</label>
								</div>
								<div class="col-4 p-2"></div>
								<div class="col-4 p-2">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Oportunidades</p>
										<textarea class="form-control" type="text" v-model="form.oportunidades">
										</textarea>
										<characters-available :max="500" :text="form.oportunidades"></characters-available>

									</label>
								</div>
								<div class="col-4 p-2">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Amenazas</p>
										<textarea class="form-control" type="text" v-model="form.amenazas">
										</textarea>
										<characters-available :max="500" :text="form.amenazas"></characters-available>

									</label>
								</div>
							</div>
						</div>
						<div class="s-collapse__header" data-toggle="collapse" data-target="#c_feg_m_2" aria-expanded="true" aria-controls="c_feg_m_2">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Valoración General</span></h6>
							</div>
						</div>
						<div id="c_feg_m_2" class="collapse show" aria-labelledby="headingOne">
							<div class="py-2">
								<table class="w-100 s-table">
									<thead>
										<tr>
											<th>
												NOMBRE DE FILIAL
											</th>
											<th>
												ACTIVIDAD
											</th>
											<th>
												RATING LARGO
											</th>
											<th>
												CUOTA DE BANQUE...
											</th>
											<th>
												ÚLTIMO AUTORIZADO
											</th>
											<th>
												FORMALIZADO
											</th>
											<th>
												DISPUESTO
											</th>
											<th>
												PROPUESTO
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(c, index) in getClients()">
											<td>
												{{ c.name }}
											</td>
											<td>
												{{c.activity}}
											</td>
											<td>
												CCC+
											</td>
											<td>
												
											</td>
											<td>
												6,000,000
											</td>
											<td>
												0
											</td>
											<td>
												0
											</td>
											<td>
												4,000,000
											</td>
										</tr>
										<tr >
											<td>
												TOTAL
											</td>
											<td>
												
											</td>
											<td>
												CCC+
											</td>
											<td>
												
											</td>
											<td>
												6,000,000
											</td>
											<td>
												0
											</td>
											<td>
												0
											</td>
											<td>
												4,000,000
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="d-flex flex-wrap mx-n2">
								<div class="col-4">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios evaluación</p>
										<textarea class="form-control" type="text" v-model="form.comentariosEvaluacion">
										</textarea>
										<characters-available :max="1000" :text="form.comentariosEvaluacion"></characters-available>

									</label>
									
								</div>
							</div>
						</div>
						<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_feg_m_3" aria-expanded="true" aria-controls="c_feg_m_3">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Análisis con respecto Competidores</span></h6>
							</div>
						</div>
						<div id="c_feg_m_3" class="collapse" aria-labelledby="headingOne">
							<div class="d-flex flex-wrap mx-n2">
								<div class="col-4 p-2">
									<label class="d-block">
										<p class="m-0"><span class="text-danger">*</span> Información de los competidores</p>
										<div class="s-tf">
											<label class="s-tf__item">
												<input type="radio" name="feg_idlc" value="SI" v-model="form.informacionCompetidores">
												<span class="s-tf__btn">SI</span>
											</label>
											<label class="s-tf__item" >
												<input type="radio" name="feg_idlc" value="NO" v-model="form.informacionCompetidores">
												<span class="s-tf__btn">NO</span>
											</label>
										</div>
									</label>
								</div>
								<div class="col-4  p-2" v-if="form.informacionCompetidores == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios sobre Información de los Competidores</p>
										<textarea class="form-control" type="text" v-model="form.comentariosInformacionCompetidores">
										</textarea>
									</label>
								</div>
							</div>
						</div>
						<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_feg_m_4" aria-expanded="true" aria-controls="c_feg_m_4">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Reuniones con los clientes</span></h6>
							</div>
						</div>
						<div id="c_feg_m_4" class="collapse" aria-labelledby="headingOne">
							<div class="d-flex py-2 flex-wrap">
								<div class="col-4 p-2">
									<label class="d-block">
										<p class="m-0"><span class="text-danger">*</span> El cliente ha sido visitado en los últimos 12 meses</p>
										<div class="s-tf">
											<label class="s-tf__item">
												<input type="radio" name="feg_cv12m" value="SI" v-model="form.clienteVisitado12Meses">
												<span class="s-tf__btn">SI</span>
											</label>
											<label class="s-tf__item" >
												<input type="radio" name="feg_cv12m" value="NO" v-model="form.clienteVisitado12Meses">
												<span class="s-tf__btn">NO</span>
											</label>
										</div>
									</label>
								</div>
							
								<div class="col-4 p-2" v-if="form.clienteVisitado12Meses == 'SI'">
									<span class="d-block">
										<p class="m-0"><span class="text-danger">*</span> Fecha</p>
										<date-picker style="width: 100%" v-model="form.fechaVisita" valueType="format"></date-picker>
									</span>
								</div>
								<div class="col-4 p-2"  v-if="form.clienteVisitado12Meses == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Asistentes</p>
										<select class="form-control" type="text" v-model="form.asistentes">
											<option value=""></option>
											<option value="Manejador">Manejador</option>
											<option value="Analista">Analista</option>
											<option value="Ambos">Ambos</option>
										</select>
									</label>
								</div>
								<div class="col-12  p-2" v-if="form.clienteVisitado12Meses == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios sobre la visita</p>
										<textarea class="form-control" type="text" v-model="form.comentariosSobreVisita">
										</textarea>
										<characters-available :max="150" :text="form.comentariosSobreVisita"></characters-available>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>
